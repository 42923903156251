import { LoadingButton } from '@mui/lab';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { updateOvertimeStaffValidity } from 'src/API/overtime';
import { useHead } from 'src/context/HeadContext';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

export default function OvertimeValidityChecker() {
  const { helmet, favicon } = useHead();
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [staffAttendances, setStaffAttendances] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFileChange = (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Extract relevant columns
        const extractedData = jsonData
          .slice(2)
          .map((row) => {
            const date = row[3];
            const firstPunch = row[5];
            const lastPunch = row[6];

            const parseDateTime = (date, time) => {
              if (!date || !time) return null;

              // Format date and time as a local date-time string (YYYY-MM-DD HH:mm:ss)
              const dateTimeString = `${date} ${time}:00`;
              return dateTimeString;
            };

            return {
              staffCode: row[1],
              staffName: row[2],
              firstPunch: parseDateTime(date, firstPunch),
              lastPunch: parseDateTime(date, lastPunch),
            };
          })
          .filter(
            (record) =>
              record.staffCode &&
              record.staffCode !== 'First Name' &&
              !record.staffCode.includes('Statistics') &&
              !record.staffCode.includes('Department:')
          );

        setStaffAttendances(extractedData);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleReset = () => {
    setStaffAttendances([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input
    }
  };

  const handleUploadClick = async () => {
    try {
      setIsSubmitting(true);
      await updateOvertimeStaffValidity({
        staff_attendances: staffAttendances.map((staffAttendance) => ({
          staff_code: staffAttendance.staffCode,
          first_punch: staffAttendance.firstPunch,
          last_punch: staffAttendance.lastPunch,
        })),
      });
      toast.success('Upload successful!');
      handleReset();
    } catch (error) {
      toast.success(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    { field: 'staffCode', headerName: 'Staff Code', width: 200 },
    { field: 'staffName', headerName: 'Staff Name', width: 200 },
    {
      field: 'firstPunch',
      headerName: 'First Punch',
      width: 200,
      renderCell: ({ firstPunch }) => (firstPunch ? dayjs(firstPunch).format('DD MMMM YYYY, HH:mm') : null),
    },
    {
      field: 'lastPunch',
      headerName: 'Last Punch',
      width: 200,
      renderCell: ({ lastPunch }) => (lastPunch ? dayjs(lastPunch).format('DD MMMM YYYY, HH:mm') : null),
    },
  ];

  console.log('staffAttendances', staffAttendances);

  return (
    <>
      <Helmet>
        <title>{helmet.title}</title>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
      </Helmet>
      <Box sx={{ px: 4, py: 3 }}>
        <input ref={fileInputRef} type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
        <Stack direction="row" justifyContent="space-between" alignContent="center" sx={{ py: 2 }}>
          <Typography variant="h4">Data from Excel:</Typography>
          <LoadingButton
            color="color"
            variant="contained"
            loading={isSubmitting}
            disabled={staffAttendances.length === 0}
            onClick={handleUploadClick}
          >
            Upload
          </LoadingButton>
        </Stack>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, border: '1px solid #ccc' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field} align={column.align} style={{ width: column.width }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center" sx={{ color: 'grey' }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : staffAttendances.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data
                  </TableCell>
                </TableRow>
              ) : (
                staffAttendances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => (
                      <TableCell key={column.field} align={column.align}>
                        {column.renderCell ? column.renderCell(row, rowIndex) : row[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={staffAttendances.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
