import { Axios } from 'src/utils';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export const overtimeShifts = async (body) => {
  try {
    const response = await Axios.get(`/overtime-shifts`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const overtimeRequest = async ({ start, end, search, size, page, status, staffId, departmentId }) => {
  const now = dayjs();
  const params = {
    include: ['requesterUser', 'overtimeStaffs'],
    filter: {
      date_range_by_created_at: {
        start_at: start.utc().format(),
        end_at: (end.isAfter(now) ? now.utc() : end.utc()).format(),
      },
      code: search,
    },
    page_size: size,
    page,
  };

  if (staffId) {
    params.filter.staff_id = staffId;
  }
  if (departmentId) {
    params.filter.department_id = departmentId;
  }
  if (status) {
    params.filter.status = status;
  }
  try {
    const response = await Axios.get(`/overtime-requests`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const overtimeRequestDetail = async (
  id,
  includeOvertimeRequestVoidReason,
  includeOvertimeRequestRejectedReason
) => {
  const params = {
    include: ['requesterUser', 'staffs.staffImage'],
  };
  if (includeOvertimeRequestVoidReason) {
    params.include.push('overtimeRequestVoidReason');
  }
  if (includeOvertimeRequestRejectedReason) {
    params.include.push('overtimeRequestRejectedReason');
  }
  try {
    const response = await Axios.get(`/overtime-requests/${id}`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const submitOvertimeRequest = async (body) => {
  try {
    const response = await Axios.post(`/overtime-requests`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const approveOvertimeRequest = async (id) => {
  try {
    const response = await Axios.put(`/overtime-requests/${id}/approve`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rejectOvertimeRequest = async (id, body) => {
  try {
    const response = await Axios.put(`/overtime-requests/${id}/reject`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOvertimeStaffValidity = async (body) => {
  try {
    const response = await Axios.put(`admin/overtime-staffs/validity`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
